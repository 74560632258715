const btnOpen = document.querySelector('.js-open')
export const formPopup = document.querySelector('.form-popup')
const overlays = document.querySelectorAll('.popup-dark__overlay')
const contentsPopup = document.querySelectorAll('.popup-dark__content')
const btnClose = document.querySelectorAll('.popup-dark__btn-close')
const popups = document.querySelectorAll('.popups')
const body = document.querySelector('body')

overlays.forEach(overlay => {
    overlay.addEventListener('click', () => {
        closePopup()
    })
})

export const openPopup = (popup) => {
    popup.classList.add('popup-dark--active')
    body.classList.add('no-scroll')
}

export const closePopup = () => {
    popups.forEach(popup => {
        popup.classList.remove('popup-dark--active')
    })

    body.classList.remove('no-scroll')
}

btnClose?.forEach(btn=> {
    btn.addEventListener('click', evt => {
        evt.preventDefault()
        closePopup()
    })
})

btnOpen?.addEventListener('click', evt => {
    evt.preventDefault()
    openPopup(formPopup)
})

contentsPopup.forEach(content => {
    content.addEventListener('click', (evt) => {
        evt.stopPropagation()
    })
})


document.addEventListener('keydown', (evt) => {
    const keyName = evt.keyCode

    if (keyName === 27) {
        closePopup()
    }
})

