import { closePopup, openPopup } from './popups-dark'
import { formPopup } from './popups-dark'

const $form = document.querySelector('.form')
const check = document.getElementById('check')
const inputs = document.querySelectorAll('.input')
const successPopup = document.querySelector('.success')
const $inputNumber = document.querySelector('.input-number')
const $inputID = document.querySelector('.input-id')
const inputWrap = document.querySelector('.input__wrap')
const formBtn = document.querySelector('.form__btn')

let isCheck

const checkInput = () => {
  const inputNumberValue = $inputNumber.value.trim()
  const inputIDValue = $inputID.value.trim()

  if (inputNumberValue === '') {
    document
      .querySelector('.input__wrap--number')
      .classList.add('input__wrap--error')
    isCheck = false
  }

  if (inputIDValue === '') {
    document
      .querySelector('.input__wrap--id')
      .classList.add('input__wrap--error')
    isCheck = false
  }

  if (String($inputNumber.value).length < 8) {
    isCheck = false
  }

  if (String($inputNumber.value).length >= 8) {
    isCheck = true
  }

  return isCheck
}

$inputNumber.addEventListener('input', (evt) => {
  evt.target.value = evt.target.value.replace(/\D/g, '')

  if (String(evt.target.value).length > 8) {
    evt.target.value = String(evt.target.value).slice(0, 8)
  }

  if (String(evt.target.value).length !== 8) {
    inputWrap.classList.add('input__wrap--error')
  } else {
    inputWrap.classList.remove('input__wrap--error')
  }
})

$inputID.addEventListener('input', (evt) => {
  document
    .querySelector('.input__wrap--id')
    .classList.remove('input__wrap--error')
})

$form?.addEventListener('submit', (evt) => {
  evt.preventDefault()
  checkInput()

  if (isCheck) {
    const data = new FormData()

    const CONFIG = {
      method: 'post',
      url: 'https://paricup.pari-api.ru/api/two-tickets-per-bets/create',
      data: data,
    }

    data.append('club', 'NN')
    data.append('account_number', $inputNumber.value)
    data.append('fan_id', $inputID.value)
    data.append('agreement', '1')

    fetch('https://paricup.pari-api.ru/api/two-tickets-per-bets/create', {
      method: 'POST',
      redirect: 'follow',
      body: data,
    })
      .then((response) => {
        if (response.ok) {
          setTimeout(() => {
            closePopup()
            openPopup(successPopup)
          }, 100)
        } else {
          throw new Error('Error')
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }
})

$form?.addEventListener('input', (evt) => {
  check.checked === true
    ? formBtn.removeAttribute('disabled', 'disabled')
    : formBtn.setAttribute('disabled', 'disabled')
})
