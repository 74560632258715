const question = document.querySelector('.aside__question')
const popup = document.querySelector('.aside__popup')

const MOBILE_WIDTH = 999

let isMobile = false

const setIsMobile = () => window.innerWidth <= MOBILE_WIDTH ? isMobile = true : isMobile = false

window.addEventListener('resize', () => {
   setIsMobile()
})

setIsMobile()

question?.addEventListener('click', function () {
   if(isMobile) {
      popup.classList.toggle('aside__popup--active')
   }

})
